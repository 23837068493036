/// <reference path='../../../../../node_modules/@types/bootstrap.datepicker/index.d.ts' />
/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />

class DatePicker {
    private static initDatePicker(dateInput: JQuery, datePickers: JQuery): void {
        dateInput.attr('autocomplete', 'off');
        let chosenDate: JQuery = dateInput.datepicker()
            .on('changeDate', () => {
                chosenDate.data('datepicker').hide();
            })
            .on('show', () => {
                for (var j = 0; j < datePickers.length; j++) {
                    if (datePickers[j] != dateInput.get(0)) {
                        var datepicker = $(datePickers[j]).data('datepicker');
                        if (datepicker.picker[0].style.display === "block") {
                            datepicker.hide();
                        }
                    }
                }
            });
    }

    public static init(): void {
        let datePickers: JQuery = $('.form-control.datepicker');
        datePickers.each((_index: number, element: Element) => {
            DatePicker.initDatePicker($(element), datePickers);
        });
    }
}

$(document).ready(() => {
    DatePicker.init();
});